export default {
  save: (data) => {
    localStorage.setItem("token", data);
  },
  get: () => {
    return localStorage.getItem("token");
  },
  remove: () => {
    localStorage.removeItem("token");
  },
};
