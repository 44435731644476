import React, { startTransition, useEffect, useState } from "react";
import { RemoteTable } from "@package/table";
import { useLocalDispatch } from "@src/javascripts/common/useDispatch";
import ListCards from "./ListStatistic";
import { ResponseCode } from "@src/global/layout";
import SaleStatictis from "./SaleStatistic";
import UserStatictis from "./UserStatistics";

const Dashboard = () => {
  const [Summary, setSummary] = useState({});

  const getListStatistics = () => {
    useLocalDispatch({
      type: "dashboard/ListStatistics",
      callback: (res) => {
        if (res.code === ResponseCode.Success) {
          setSummary(res.data);
        }
      },
    });
  };

  useEffect(() => {
    startTransition(() => {
      getListStatistics();
    });
  }, []);

  return (
    <div className="flex flex-col gap-[20px]">
      <ListCards data={Summary} />
      <SaleStatictis />
      <UserStatictis />
    </div>
  );
};

export default Dashboard;
