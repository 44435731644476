import { IPageModel } from "@package/store";

export default {
  namespace: "dashboard",
  actionType: {
    Post: {
      ListStatistics: "/model/index/listStatistics", //数据统计
      AmountStatistics: "/model/index/amountStatistics", //销售统计
      UserStatistics: "/model/index/userStatistics", //用户统计
    },
  },
} as IPageModel;
