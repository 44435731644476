import { ResponseCode } from "@src/global/layout";
import { EnumDateOptions } from "@src/javascripts/common/enums/EnumDateOptions";
import { useLocalDispatch } from "@src/javascripts/common/useDispatch";
import { Radio } from "antd";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";
import CustomizedLabel from "@src/javascripts/pages/dashboard/components/customizedLabel";
import CustomizedAcisTick from "@src/javascripts/pages/dashboard/components/customizedAxisTick";
import { EnumDataValue } from "@src/javascripts/pages/dashboard/common/enums/EnumDataValue";
import { EnumDataKeyDesc } from "@src/javascripts/pages/dashboard/common/enums/EnumDataKeyDesc";

// const data = [
//   {
//     name: "Page A",
//     uv: 4000,
//     pv: 2400,
//     amt: 2400,
//   },
//   {
//     name: "Page B",
//     uv: 3000,
//     pv: 1398,
//     amt: 2210,
//   },
//   {
//     name: "Page C",
//     uv: 2000,
//     pv: 9800,
//     amt: 2290,
//   },
//   {
//     name: "Page D",
//     uv: 2780,
//     pv: 3908,
//     amt: 2000,
//   },
//   {
//     name: "Page E",
//     uv: 1890,
//     pv: 4800,
//     amt: 2181,
//   },
//   {
//     name: "Page F",
//     uv: 2390,
//     pv: 3800,
//     amt: 2500,
//   },
//   {
//     name: "Page G",
//     uv: 3490,
//     pv: 4300,
//     amt: 2100,
//   },
// ];

// const EnumDataValue = {
//   week: "dayValue",
//   month: "monthValue",
//   year: "yearValue",
// };

const SaleStatictis = (props) => {
  const [showDateType, setDateType] = useState("week");
  const [dataValue, setDataValue] = useState("dayValue");
  const [data, setData] = useState([]);

  const getAmountStatistics = () => {
    useLocalDispatch({
      type: "dashboard/AmountStatistics",
      data: {
        type: showDateType,
        pageSize: 15,
      },
      callback: (res) => {
        if (res.code == ResponseCode.Success) {
          setData(res.data);
        }
      },
    });
  };

  // useEffect(() => {
  //   getAmountStatistics();
  // }, []);

  useEffect(() => {
    getAmountStatistics();
  }, [showDateType]);

  return (
    <>
      <div
        className={classNames("border-[1px] rounded-[8px] bg-white p-[20px]")}
      >
        <div className="flex flex-row justify-between items-center pb-[12px]">
          <div className="text-[16px]">销售统计</div>
          <div>
            <Radio.Group
              options={EnumDateOptions}
              optionType="button"
              buttonStyle="solid"
              value={showDateType}
              onChange={(evt) => {
                setDateType(evt.target.value);
                setDataValue(EnumDataValue[evt.target.value]);
              }}
            ></Radio.Group>
          </div>
        </div>
        <div style={{ height: "340px" }}>
          <ResponsiveContainer width={"100%"} height={"100%"}>
            <LineChart
              width={500}
              height={300}
              data={data}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey={dataValue} tick={<CustomizedAcisTick />} />
              <YAxis />
              <Tooltip />
              <Legend verticalAlign="top" height={36} margin={{ top: -20 }} />
              <Line
                type="monotone"
                dataKey="orderNum"
                stroke="#8884d8"
                name={EnumDataKeyDesc["orderNum"]}
                label={<CustomizedLabel label={EnumDataKeyDesc["orderNum"]} />}
                activeDot={{ r: 8 }}
                unit={"笔"}
              />
              <Line
                type="monotone"
                dataKey="amount"
                name={EnumDataKeyDesc["amount"]}
                label={<CustomizedLabel label={EnumDataKeyDesc["amount"]} />}
                stroke="#82ca9d"
                unit="元"
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    </>
  );
};

export default SaleStatictis;
