import React from "react";

const CustomizedLabel = (props) => {
  const { x, y, stroke, value, label } = props;

  return (
    <text x={x} y={y} dy={-4} fill={stroke} fontSize={10} textAnchor="middle">
      {label}: {value||0}
    </text>
  );
};

export default CustomizedLabel;
