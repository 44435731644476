import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Breadcrumb } from "antd";
import ErrorBoundary from "@src/javascripts/components/ErrorBoundary";

const MenuBreadcrumb = (props) => {
  const _location = useLocation();
  const { Menus } = props; //useSelector((state: any) => state.globalState ?? {});

  const _paths = _location.pathname.split("/");

  const MenuEnum = {
    list: "列表",
    add: "新增",
    edit: "修改",
    fastAdd: "批量新增",
  };

  const getMatchMenu = ({ menus }): Array<any> => {
    if (!menus) return [];

    let _newPath = _paths
      .slice(1, _paths.length)
      .reduce((prev: Array<string>, current, index) => {
        if (index === 0) return ["/" + current];
        else {
          prev.push(prev[prev.length - 1] + "/" + current);

          return prev;
        }
      }, []);

    let _filterMenus = menus;

    let _matchMenus = [];

    _newPath.forEach((path: string) => {
      _filterMenus = _filterMenus.find((item) => item.path === path);

      const { children = [], ...restMenu } = _filterMenus || {};

      if (!!children) {
        _filterMenus = children;
      }

      _matchMenus.push(restMenu);
    });

    return _matchMenus;
  };

  const RenderBreadcrumbItem = useCallback(
    ({ menus }) => {
      return (menus ?? []).map(function (item, index) {
        return (
          <Breadcrumb.Item key={`${item}-${index}`}>
            {item.menuName}
          </Breadcrumb.Item>
        );
      });
    },
    [Menus]
  );

  return (
    //@ts-ignore
    <ErrorBoundary>
      <Breadcrumb>
        <Breadcrumb.Item>首页</Breadcrumb.Item>
        <RenderBreadcrumbItem
          menus={getMatchMenu({ menus: Menus })}
        ></RenderBreadcrumbItem>
      </Breadcrumb>
    </ErrorBoundary>
  );
};

export default MenuBreadcrumb;
