import { IPageModel } from "@package/store";

export default {
  namespace: "order",
  actionType: {
    Post: {
      OrderList: "/model/order/list",
    },
  },
} as IPageModel;
