export const EnumOrderStatus = {
  1: "支付宝",
  2: "微信",
};

export const EnumPayStatus = {
  0: "未支付",
  1: "已支付",
  2: "已退款",
};
