import React, { Suspense } from "react";
import "dayjs/locale/zh";
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";
import dayjs from "dayjs";
import zhCN from "antd/lib/locale/zh_CN";
import ConfigProvider from "antd/es/config-provider";
import BootstrapApp from "./launch";
import { BrowserRouter as Router, Outlet } from "react-router-dom";

import PageRouter, { Menus } from "./router";
import { initStore } from "@package/store";
import { Provider as StoreProvider } from "react-redux";
import { Spin } from "antd";
import { AnimatePresence } from "framer-motion";

let _store = initStore();
window.$store = _store;

dayjs.extend(weekday);
dayjs.extend(localeData);

const App = () => {
  return (
    <StoreProvider store={_store.store}>
      <ConfigProvider locale={zhCN} virtual={true}>
        <BootstrapApp>
          <AnimatePresence>
            <Router>
              <Suspense fallback={<Spin tip="页面加载中"></Spin>}>
                <PageRouter></PageRouter>
                <Outlet />
              </Suspense>
            </Router>
          </AnimatePresence>
        </BootstrapApp>
      </ConfigProvider>
    </StoreProvider>
  );
};

export default App;
