import token from "@src/javascripts/common/storage/token";
import { RenderIcon } from "@package/icons";
import { Form, Checkbox, Input, Button, message } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ResponseCode } from "@src/global/layout";
import { useLocalDispatch } from "@src/javascripts/common/useDispatch";

const AccountLogin = () => {
  const [form] = Form.useForm();
  const [isChecked, setChecked] = useState(false);
  const navigate = useNavigate();

  const login = async () => {
    await form.validateFields();

    const _data = form.getFieldsValue();

    console.log(_data, "--data");

    const { password, ...restData } = _data;

    if (_data.rememberMe) {
      localStorage.setItem("account", JSON.stringify(restData));
    }

    const res = await window.$store.dispatchAsync({
      type: "login/Login",
      data: _data,
    });

    if (res.code === ResponseCode.Success) {
      // token.save(res.data.access_token);
      navigate("/");
      setTimeout(() => {
        useLocalDispatch({
          type: "global/UserProfile",
        });
      });
      return;
    }

    // if (res.code != 0) {
    message.error(res.message);
    return;
    // }
  };

  const chkChange = (evt) => {
    setChecked(evt.target.checked);
  };

  const keyDown = (evt) => {
    if (evt.keyCode === 13) {
      login();
    }
  };

  useEffect(() => {
    if (localStorage.getItem("account")) {
      try {
        form.setFieldsValue(JSON.parse(localStorage.getItem("account")));
      } catch (ex) {}
    }
  }, []);

  return (
    <div className="account-login">
      <Form
        form={form}
        autoComplete={"off"}
        className="flex gap-[12px] flex-col"
      >
        <Form.Item
          name={"username"}
          rules={[{ required: true, message: "账号不能为空" }]}
        >
          <Input
            prefix={<RenderIcon type="UserOutlined" />}
            placeholder="账号"
            allowClear={true}
            onKeyDown={keyDown}
          ></Input>
        </Form.Item>
        <Form.Item
          name={"password"}
          rules={[{ required: true, message: "密码不能为空" }]}
        >
          <Input.Password
            prefix={<RenderIcon type="LockOutlined" />}
            placeholder="密码"
            allowClear={true}
            onKeyDown={keyDown}
          ></Input.Password>
        </Form.Item>
        <Form.Item
          name={"rememberMe"}
          initialValue={true}
          valuePropName="checked"
        >
          <Checkbox>记住密码</Checkbox>
        </Form.Item>
      </Form>
      <div className="field-item" style={{ marginTop: "20px" }}>
        <Button type="primary" onClick={login}>
          登录
        </Button>
      </div>
    </div>
  );
};

export default AccountLogin;
