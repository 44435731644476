export const EnumDateOptions = [
  {
    label: "周",
    value: "week",
  },
  {
    label: "月",
    value: "month",
  },
  {
    label: "年",
    value: "year",
  },
];
