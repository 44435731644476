import React, { ErrorInfo, Component } from "react";

interface IErrorBoundaryProps {
  children?: JSX.Element;
}

interface IState {
  hasError: Boolean;
  message?: ErrorInfo;
}

class ErrorBoundary extends Component<IErrorBoundaryProps, IState, any> {
  state = {
    hasError: false,
    messgae: null,
  } as IState;
  //   props: IProps;
  //   setState: Function;
  constructor(props: IErrorBoundaryProps) {
    super(props);
    // this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, message: error.message };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.log(errorInfo, error, "error");
    //@ts-ignore
    this.setState({ messgae: errorInfo });
  }

  render(): JSX.Element {
    if (this.state.hasError) {
      return <>{this.state.message}</>;
    }

    //@ts-ignore
    return <> {this.props.children as React.ReactElement<any>}</>;
  }
}

export default ErrorBoundary;
