import React, { useRef } from "react";
import { RemoteTable } from "@package/table";
import { Button, Space, Switch, Tooltip, message } from "antd";
import { RenderIcon } from "@package/icons";
import EditGoods from "../edit";
import { IModalFormRef } from "@package/form";
import { useLoadTableData } from "@src/javascripts/common/useTableCallback";
import { useCopyToClipboard } from "usehooks-ts";
import { ResponseCode } from "@src/global/layout";
import { useDispatchAsync } from "@src/javascripts/common/useDispatch";

const GoodList = () => {
  const _editRef = useRef<IModalFormRef>();
  const [table] = RemoteTable.useTable();

  const [copiedText, copy] = useCopyToClipboard();

  const _columns = [
    {
      title: "商品ID",
      dataIndex: "productId",
      width: 60,
    },
    {
      title: "商品码",
      dataIndex: "productCode",
      width: 140,
      render: (data: string) => {
        return (
          <Space>
            {data}
            <Button
              type="link"
              onClick={() =>
                copy(data)
                  .then((res) => {
                    message.success("复制成功");
                  })
                  .catch((res) => {
                    message.error("复制失败");
                  })
              }
            >
              <RenderIcon type="CopyOutlined" />
            </Button>
          </Space>
        );
      },
    },
    {
      title: "商品名称",
      dataIndex: "productName",
      // width: 160,
      filter: true,
    },
    {
      title: "商品内容",
      dataIndex: "productDetails",
      width: 160,
      render: (data) => {
        return (
          <Space direction="vertical">
            {(data || [])?.map?.((item: any) => {
              return item.detailName + `【${item.quantity}次】`;
            })}
          </Space>
        );
      },
    },
    {
      title: "描述",
      dataIndex: "productDesc",
      ellipsis: {
        showTitle: false,
      },
      render: (data: string) => {
        return (
          <Tooltip placement="topLeft" title={data}>
            {data}
          </Tooltip>
        );
      },
    },
    {
      title: "划线价",
      dataIndex: "currentPrice",
      align: "right",
    },
    {
      title: "售价",
      dataIndex: "originalPrice",
      align: "right",
    },
    {
      title: "状态",
      dataIndex: "statusName",
      align: "center",
      render: (data, record) => {
        return (
          <>
            <Switch
              onChange={(data) => {
                changeStatus(record.status, record);
              }}
              checked={record.status}
              checkedChildren={data}
              unCheckedChildren={data}
            ></Switch>
          </>
        );
      },
    },
    {
      title: "操作",
      render: (data, record) => {
        return (
          <Button
            type="link"
            onClick={() => {
              _editGoods(record);
            }}
            icon={<RenderIcon type="EditOutlined" />}
          >
            编辑
          </Button>
        );
      },
    },
  ];

  const _editGoods = (data?: any) => {
    _editRef.current.setVisible(true);

    if (data) _editRef.current.setFieldsValue(data);
  };

  const changeStatus = async (status, record) => {
    const _res = await useDispatchAsync({
      type: "goods/UpdateGoods",
      data: {
        ...record,
        status: status == 0 ? 1 : 0,
        statusName: status === 0 ? "显示" : "隐藏",
      },
    });

    if (_res.code === ResponseCode.Success) {
      table?.reload?.();
      return false;
    }

    return true;
  };

  return (
    <>
      <RemoteTable
        table={table}
        columns={_columns}
        scroll={{ y: true }}
        rowKey={"productId"}
        actionBar={
          <>
            <Button
              onClick={_editGoods}
              type="primary"
              icon={<RenderIcon type="PlusOutlined" />}
            >
              新增商品
            </Button>
          </>
        }
        loadData={useLoadTableData("goods/GoodsList", () => {
          return {};
        })}
      ></RemoteTable>
      <EditGoods ref={_editRef} table={table} />
    </>
  );
};

export default GoodList;
