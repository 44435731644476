import React, { useEffect, useRef, useState } from "react";
import { Menu, Dropdown, Button } from "antd";
import Logout from "../Logout";
import "./index.scss";
import {
  UserOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  RenderIcon,
} from "@package/icons";
import UpdatePwdModal from "./updateModal";

const MenuContent = (props) => {
  const { userInfo, onLogout } = props;
  const modalRef = useRef<any>();

  return (
    <>
      <Menu mode="inline">
        <Menu.Item key={1} icon={<RenderIcon type="UserOutlined" />}>
          &nbsp;{userInfo?.userRealName ?? ""}
        </Menu.Item>
        {/* <Menu.Item
          onClick={() => modalRef.current.show()}
          icon={<RenderIcon type="UserOutlined" />}
        >
          我的账号
        </Menu.Item> */}
        <Menu.Item
          key={"modify_pwd"}
          onClick={() => modalRef.current.show()}
          icon={<RenderIcon type="FormOutlined" />}
        >
          &nbsp;修改密码
        </Menu.Item>
        <Menu.Item key={3}>
          <Logout {...props} onLogout={onLogout}></Logout>
        </Menu.Item>
      </Menu>
      <UpdatePwdModal ref={modalRef} onLogout={onLogout}></UpdatePwdModal>
    </>
  );
};
const PageHeader = (props) => {
  const { collapse, triggerSider, onLogout, userInfo, adminTitle = "" } = props;
  // console.log(userInfo, "user info");
  return (
    <>
      <div className="logo-box">
        <div className="header">
          <span className="logo"></span>
          <span className="title-text">{adminTitle}</span>
        </div>
      </div>
      <div className="head-content">
        <Button
          type="link"
          className={`menu-collapse-btn`}
          onClick={triggerSider}
          style={{ fontSize: "20px" }}
        >
          {collapse ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        </Button>
        <div style={{ float: "right", overflow: "hidden" }}>
          <div style={{ float: "left" }}>
            <Dropdown
              overlay={
                <MenuContent userInfo={userInfo ?? {}} onLogout={onLogout} />
              }
              placement="bottomRight"
            >
              <button
                style={{
                  float: "right",
                  background: "none",
                  margin: "0px",
                  padding: "0px",
                  border: 0,
                  outline: 0,
                  cursor: "pointer",
                }}
              >
                <span style={{ fontSize: "14px" }}>
                  <UserOutlined style={{ fontSize: "20px" }} />{" "}
                  {userInfo?.userRealName ?? ""}
                </span>
              </button>
            </Dropdown>
          </div>
        </div>
      </div>
    </>
  );
};

export default PageHeader;
