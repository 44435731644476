import React, { useState, useEffect, useMemo } from "react";

import { Layout } from "antd";
import SidebarMenu from "./Sidebar";
import PageHeader from "./Header";
import MenuBreadcrumb from "./Breadcrumb";
import "./layout.scss";
import LayoutOption from "@src/global/layout";
import token from "@js/common/storage/token";
import { createSearchParams, useNavigate } from "react-router-dom";

const { Header, Sider } = Layout;

const { layoutSetting } = LayoutOption;

const wrapperMenu = (data) => {
  return (data || []).map((item) => {
    if (item.children?.length > 0) {
      return {
        key: item.path,
        label: item.menuName,
        title: item.menuName,
        url: item.path,
        children: wrapperMenu(item.chidlren),
      };
    }

    return {
      key: item.path,
      label: item.menuName,
      title: item.menuName,
      url: item.path,
    };
  });
};

const MainLayout = (props) => {
  const _parmas = createSearchParams(location.search);

  const userInfo = {}; // useSelector((state: any) => state.global?.userInfo || {});

  const navigate = useNavigate();

  const onLogout = () => {
    window.$store.dispatchAsync({
      type: "login/Logout",
      callback: (res) => {
        token.remove();
        // navigate("/login");
      },
    });
  };

  const { Menus } = props;

  const { sidebarOption } = layoutSetting;
  const {
    collapsedWidth = 80,
    defaultWidth = 260,
    defaultCollapse = false,
  } = sidebarOption;

  const [collapse, setCollapse] = useState(defaultCollapse);

  useEffect(() => {}, []);

  const triggerSider = () => {
    const _collapse = !collapse;

    setCollapse(_collapse);
  };

  const _menus = useMemo(() => {
    return Menus(userInfo);
  }, [userInfo]);

  return (
    <>
      <Layout>
        <Header>
          <PageHeader
            collapse={collapse}
            triggerSider={triggerSider}
            onLogout={onLogout}
            userInfo={userInfo}
            adminTitle={LayoutOption.systemTitle}
          ></PageHeader>
        </Header>
        <Layout className="main-wrapper">
          <Sider
            width={defaultWidth}
            trigger={null}
            collapsible
            collapsed={collapse}
            style={{ position: "fixed", left: 0, zIndex: 999 }}
          >
            <SidebarMenu Menus={_menus} />
          </Sider>
          <main
            className="main-content"
            style={{
              paddingLeft: `${collapse ? collapsedWidth : defaultWidth}px`,
            }}
          >
            <div>
              <MenuBreadcrumb Menus={_menus} />
            </div>
            <div className="router-content">{props?.children}</div>
          </main>
        </Layout>
      </Layout>
    </>
  );
};

export default MainLayout;
