import React, { useState } from "react";
import { ITabFormItemProp, TabForm } from "@package/form";
import privancyProtocol from "./privancyProtocol";
import userProtocol from "./userProtocol";
import chargeProtocol from "./chargeProtocol";

const ProtocolManage = () => {
  const [tabItems, setTabItems] = useState<ITabFormItemProp[]>([
    {
      label: "隐私协议",
      key: "base_tab",
      closable: false,
      children: privancyProtocol,
    },
    {
      label: "用户协议",
      key: "user_tab",
      closable: false,
      children: userProtocol,
    },
    {
      label: "充值协议",
      key: "charge_tab",
      closable: false,
      children: chargeProtocol,
    },
  ]);

  const _saveData = (data) => {
    console.log(data);
  };

  return (
    <>
      <TabForm
        animated={true}
        items={tabItems}
        centered={false}
        saveData={_saveData}
        tabBarStyle={{
          paddingLeft: "20px",
        }}
      ></TabForm>
    </>
  );
};

export default ProtocolManage;
