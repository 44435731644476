const routes = [
  {
    path: "/statics",
    menuName: "数据",
    icon: "ProjectOutlined",
    code: [1],
    auth: (data) => {
      return data?.userRole != 3;
    },
    sort: 8,
    children: [
      {
        path: "list",
        menuName: "用户统计",
        index: true,
        element: () => import("./user"),
      },
      {
        path: "works",
        menuName: "销售统计",
        element: () => import("./sales"),
      },
    ],
  },
];

export default routes;
