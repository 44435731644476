export default {
	namespace: "global",
	actionType: {
		Get: {
			UserProfile: "/backapi/auth/profile",
			// Logout:"/backapi/auth/logout"
		}
	},
	reducers: {
		global: (state = {}, action) => {
			// console.log(action, '---ation')
			switch (action.type) {
				case "UserProfile@@auto":
					return { ...state, userInfo: action.data.data };
				case "MenuList@@auto":
					return { ...state, menuList: action.data.data };
				case "AuthMenuList@@auto":
					return { ...state, authMenuList: action.data.data };
				case "sidebarInfo":
					return {
						...state,
						sidebarInfo: action.data,
					};
				case "Logout@@auto":
					return { ...state, userInfo: {} }
				default:
					return state;
			}
		},
	},
}