import React, { useRef } from "react";
import { RemoteTable } from "@package/table";
import { Button, Space, Tooltip, message } from "antd";
import { RenderIcon } from "@package/icons";
import { useLoadTableData } from "@src/javascripts/common/useTableCallback";
import Refund from "./refund";
import classNames from "classnames";
import { useCopyToClipboard } from "usehooks-ts";
import {
  EnumOrderStatus,
  EnumPayStatus,
} from "@src/javascripts/common/enums/EnumOrderStatus";

interface IProductDetail {
  detailId: number | string;
  detailName: string;
  quantity: number;
}

const OrderList = () => {
  const [table] = RemoteTable.useTable();

  const refundRef = useRef<any>();
  const [copiedText, copy] = useCopyToClipboard();

  const _columns = [
    {
      title: "订单号",
      dataIndex: "orderCode",
      filter: true,
      width: 120,
      render: (data) => {
        return (
          <Space size={"small"}>
            <Tooltip title={data}>
              <div
                className={classNames(
                  "max-w-[80px] whitespace-nowrap text-ellipsis overflow-hidden"
                )}
              >
                {data}
              </div>
            </Tooltip>
            <Tooltip title="复制订单号">
              <Button
                className="w-auto !p-0"
                type="link"
                icon={<RenderIcon type="CopyOutlined" />}
                onClick={() => {
                  copy(data)
                    .then((res) => message.success("复制成功"))
                    .catch((ex) => {
                      message.error("复制失败");
                    });
                }}
              />
            </Tooltip>
          </Space>
        );
      },
    },
    {
      title: "商品名称",
      dataIndex: "productName",
      filter: true,
    },
    {
      title: "商品内容",
      dataIndex: "productDetails",
      width: 140,
      render: (data: IProductDetail[]) => {
        return (
          <Space direction="vertical">
            {(data || [])?.map(
              (item: IProductDetail) => `${item.detailName}【${item.quantity}】`
            )}
          </Space>
        );
      },
    },
    {
      title: "邮箱账号",
      dataIndex: "username",
      filter: true,
    },
    {
      title: "实际支付",
      align: "right",
      dataIndex: "orderPayment",
    },
    {
      title: "支付时间",
      dataIndex: "updateTime",
      filter: true,
      type: "daterange",
      align: "right",
      render: (data) => {
        return <>{data || "----"}</>;
      },
    },
    {
      title: "订单状态",
      dataIndex: "orderStatusName",
      filter: true,
      formItemProps: {
        name: "orderStatus",
      },
      type: "select",
      formControlProps: {
        options: Object.entries(EnumPayStatus).map((item) => ({
          label: item[1],
          value: item[0],
        })),
      },
    },
    {
      title: "操作",
      render: (data, record) => {
        return (
          <Button
            type="link"
            onClick={() => {
              refundRef.current.setVisible(true);

              refundRef.current.setFieldsValue(record);
            }}
          >
            退款
          </Button>
        );
      },
    },
  ];

  return (
    <>
      <RemoteTable
        table={table}
        columns={_columns}
        scroll={{ y: true }}
        rowKey={"orderCode"}
        loadData={useLoadTableData("order/OrderList", (searchParams) => {
          const { updateTime, ...restParams } = searchParams;

          let _obj = {
            ...restParams,
          };

          if (updateTime) {
            _obj = {
              ..._obj,
              startTime: updateTime[0],
              endTime: updateTime[1],
            };
          }

          return _obj;
        })}
        actionBar={
          <Button icon={<RenderIcon type="DownloadOutlined" />}>下载</Button>
        }
      ></RemoteTable>
      <Refund ref={refundRef} table={table} />
    </>
  );
};

export default OrderList;
