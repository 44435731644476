import React from "react";
import { RemoteTable } from "@package/table";
import { Button } from "antd";
import { RenderIcon } from "@package/icons";
import { useLoadTableData } from "@src/javascripts/common/useTableCallback";

const UserSummary = () => {
  const _columns = [
    {
      title: "日期",
      dataIndex: "",
      filter: true,
      type: "daterange",
    },
    {
      title: "浏览量（PV）",
      dataIndex: "",
    },
    {
      title: "访客数（UV）",
      dataIndex: "",
    },
    {
      title: "新增注册用户",
      dataIndex: "",
    },
    {
      title: "付费用户数",
      dataIndex: "",
    },
  ];
  return (
    <>
      <RemoteTable
        columns={_columns}
        loadData={useLoadTableData("statictis/UserStatictis")}
        actionBar={
          <Button
            type="primary"
            icon={<RenderIcon type="DownloadOutlined"></RenderIcon>}
          >
            下载
          </Button>
        }
      ></RemoteTable>
    </>
  );
};

export default UserSummary;
