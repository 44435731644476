import layout from "@src/global/layout";
import { GenerateEffect, GenerateService, Service } from "@package/store";

let Routers: Array<any> = [];
let Models: Array<any> = [];
layout?.serviceSetting?.initService?.(Service);

//@ts-ignore
require
  .context("../pages", true, /^.(\/[a-zA-Z0-9]+\/)index.tsx$/, "sync")
  .keys()
  .forEach((item) => {
    //@ts-ignore
    const _content = require(`${item}`);

    if (!!Array.isArray(_content.default)) {
      _content.default.map((item) => {
        Routers.push(item);
      });
    } else {
      Routers.push(_content.default);
    }
  });

//@ts-ignore
require
  .context("../pages", true, /^.(\/[a-zA-Z0-9]+\/model\/)index.ts$/, "sync")
  .keys()
  .forEach((item) => {
    //@ts-ignore
    const _model = require(`${item}`);

    if (!!_model.default) {
      const _actionType = _model.default.actionType ?? {};
      const _services = GenerateService(_actionType);

      const { Get, Post } = _actionType;
      // console.log(_actionType, _services)
      Models.push({
        ..._model.default,
        effects: GenerateEffect({ ...(Get || {}), ...(Post || {}) }, _services),
        services: _services,
      });
    }
  });

export { Models };

export default Routers;
