import React, { useImperativeHandle, useRef } from "react";
import { IFormItemProps, ModalForm } from "@package/form";
import { RenderIcon } from "@package/icons";
import { useDispatchAsync } from "@src/javascripts/common/useDispatch";
import { ResponseCode } from "@src/global/layout";
import { message } from "antd";
import { GetUploadProps } from "@src/javascripts/common/uploadUtil";

const EditWorks = (props, ref) => {
  const { table } = props || {};
  const modalRef = useRef();

  const _formItems: Array<IFormItemProps> = [
    {
      formItemProps: {
        name: "categoryCode",
        hidden: true,
      },
    },
    {
      formItemProps: {
        name: "categoryId",
        hidden: true,
      },
    },
    {
      formItemProps: {
        name: "categoryName",
        label: "产品名称",
        rules: [{ required: true }],
      },
    },
    {
      formItemProps: {
        name: "categoryProcess",
        label: "产品工艺",
        rules: [{ required: true }],
      },
    },
    {
      formItemProps: {
        name: "categoryMaterial",
        label: "产品材质",
        rules: [{ required: true }],
      },
    },
    {
      type: "upload",
      formItemProps: {
        name: "categoryKey",
        label: "产品图片",
      },

      formControlProps: {
        listType: "picture-card",
        text: "上传图片",
        icon: <RenderIcon type="UploadOutlined" />,
        ...GetUploadProps({
          action: "/model/brand/upload",
        }),
      },
    },
    {
      type: "inputnumber",
      formItemProps: {
        label: "排序",
        name: "categorySort",
      },
    },
  ];

  useImperativeHandle(ref, () => modalRef.current, []);

  const onSave = async (data) => {
    const { categoryCode, categoryKey, ...restData } = data;

    console.log(data, "--data");

    const _res = await useDispatchAsync({
      type: !categoryCode ? "content/AddProduct" : "content/UpdateProduct",
      data: {
        ...restData,
        categoryCode,
        categoryKey: categoryKey?.[0]?.url || null,
      },
    });

    if (_res.code === ResponseCode.Success) {
      message.success("保存成功");
      table.reload();
      return;
    }
    return true;
  };

  return (
    <>
      <ModalForm
        ref={modalRef}
        title="新增/编辑产品"
        formItems={_formItems}
        onOk={onSave}
        width={680}
      ></ModalForm>
    </>
  );
};

export default React.forwardRef(EditWorks);
