import token from "@src/javascripts/common/storage/token";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import { ExitOutline } from '@pack/icons';
// import { delCookie } from '@comm/util';
// import { useAuth } from "@src/components/Auth";
import { LogoutOutlined } from "@package/icons";
import { useLocalDispatch } from "@src/javascripts/common/useDispatch";

// import "./styles/index.less";
// import { authUrl } from "@src/common/globalSetting";
// import { TokenStorage, UserInfoStorage } from "@src/common/storage/account";

const Logout = (props: any) => {
  //   const _dispatch = window.$store.dispatch;
  const navigate = useNavigate();
  const [logouting, setLogouting] = useState(false);
  //   const auth = useAuth();

  useEffect(() => {}, []);

  const logoutFn = () => {
    setLogouting(true);
    useLocalDispatch({
      type: "login/Logout",
      callback: (res) => {
        console.log(res);
      },
    });

    props?.onLogout?.();
  };

  return (
    <>
      <button
        style={{
          width: "100%",
          textAlign: "left",
          background: "none",
          margin: "0px",
          padding: "0px",
          border: 0,
          outline: 0,
          cursor: "pointer",
        }}
        disabled={logouting}
        onClick={logoutFn}
      >
        <LogoutOutlined /> 退出登录
      </button>
    </>
  );
};

export default Logout;
