import layout, { ResponseCode } from "@src/global/layout";
import token from "./storage/token";
import { message } from "antd";

export const GetUploadUrl = (options = {} as any) => {
  const { action, formKey = "" } = options || {};
  const { apiDomain, EDITOR_UPLOAD_URL, UPLOAD_URL } = layout.serviceSetting;
  let _uploadUrl = options.editor ? EDITOR_UPLOAD_URL : UPLOAD_URL;

  return !!action
    ? (apiDomain ?? "/") + action
    : _uploadUrl.replace("{formKey}", formKey ?? "");
};

export const GetUploadProps = (option = {} as any) => {
  const _defaultOption = {
    maxSize: 5 * 1024,
  };

  return {
    ...option,
    action: GetUploadUrl(option),
    data: {},
    headers: {
      authorization: token.get(),
    },
    onBeforeSuccess: function (res) {
      if (res.code === ResponseCode.Success) {
        return [{ url: res.data.fileUrl, name: res.data.fileName }];
      } else {
        return [];
      }
    },
  };
};

export const getUploadData = (data) => {
  if (!data || data.length === 0) return null;

  return data[0].fileId || data[0].id || data[0].url;
};

export const GetMediaUploadProps = (option = {} as any) => {
  const _defaultOption = {
    maxSize: 5 * 1024 * 1024,
  };

  return {
    ...option,
    action: GetUploadUrl(option),
    data: {},
    headers: {
      authorization: token.get(),
    },
    beforeUpload: (file) => {
      console.log(file);
      if (["audio/mpeg", "video/webm", "video/mp4"].indexOf(file.type) == -1) {
        message.error("请确认上传的文件格式，当前多媒体只支持mp3,mp4,webm!");
        return false;
      }

      if (file.size > (option.maxSize || _defaultOption.maxSize)) {
        message.error(
          `文件大小不能超过${
            (option.maxSize || _defaultOption.maxSize) / 1024 / 1024
          }M!`
        );
        return false;
      }
      return true;
    },
    onBeforeSuccess: function (res) {
      if (res.code === ResponseCode.Success) {
        return [{ url: res.data.fileUrl, name: res.data.fileName }];
      } else {
        return [];
      }
    },
  };
};
