const routes = [
    {
      path: "/goods",
      menuName: "商品",
      icon: "GoldOutlined",
      element: () => import("./list"),
      sort: 1,
    },
  ];
  
  export default routes;
  