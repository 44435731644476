import React from "react";
import SlideMenu from "./menuItem";
import ErrorBoundary from "@src/javascripts/components/ErrorBoundary";
import "./index.scss";

const SidebarMenu = (props) => {
  const { Menus } = props;
  return (
    //@ts-ignore
    <ErrorBoundary>
        <SlideMenu list={Menus} {...props}></SlideMenu>
    </ErrorBoundary>
  );
};

export default SidebarMenu;
