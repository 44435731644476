import React, { useImperativeHandle, useRef } from "react";
import { IFormItemProps, IModalFormRef, ModalForm } from "@package/form";
import { useDispatchAsync } from "@src/javascripts/common/useDispatch";
import { ResponseCode } from "@src/global/layout";
import { message } from "antd";
import { GetUploadProps } from "@src/javascripts/common/uploadUtil";
import { RenderIcon } from "@package/icons";

const EditCoBrand = (props, ref) => {
  const { table } = props;
  const modalRef = useRef<IModalFormRef>();

  const _formItems: Array<IFormItemProps> = [
    {
      formItemProps: {
        name: "brandCode",
        hidden: true,
      },
    },
    {
      formItemProps: {
        name: "brandId",
        hidden: true,
      },
    },
    {
      formItemProps: {
        name: "brandName",
        label: "品牌名称",
      },
    },
    {
      type: "upload",
      formItemProps: {
        name: "brandKey",
        label: "品牌LOGO",
      },
      formControlProps: {
        listType: "picture-card",
        text: "上传图片",
        icon: <RenderIcon type="UploadOutlined" />,
        ...GetUploadProps({
          action: "/model/brand/upload",
        }),
      },
    },
    {
      type: "inputnumber",
      formItemProps: {
        name: "brandSort",
        label: "排序",
      },
    },
  ];

  useImperativeHandle(ref, () => modalRef.current, []);

  const onSave = async (data) => {
    const { brandCode, brandKey, ...restData } = data;

    console.log(data, "--data");

    const _res = await useDispatchAsync({
      type: !brandCode ? "content/AddBrand" : "content/UpdateBrand",
      data: {
        ...restData,
        brandCode,
        brandKey: brandKey?.[0]?.url || null,
      },
    });

    if (_res.code === ResponseCode.Success) {
      message.success("保存成功");
      table.reload();
      return;
    }
    return true;
  };

  return (
    <>
      <ModalForm
        title="新增｜修改品牌"
        width={680}
        ref={modalRef}
        formItems={_formItems}
        onOk={onSave}
      ></ModalForm>
    </>
  );
};

export default React.forwardRef(EditCoBrand);
