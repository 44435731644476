const routes = [
  {
    path: "/content",
    menuName: "内容",
    icon: "ProductOutlined",
    sort: 5,
    children: [
      {
        path: "works_gallery",
        menuName: "产品展示",
        element: () => import("./worksGallery"),
      },
      {
        path: "co_brand",
        menuName: "合作品牌",
        element: () => import("./coBrand"),
      },
      {
        path: "protocols",
        menuName: "协议设置",
        element: () => import("./protocols"),
      },
    ],
  },
];

export default routes;
