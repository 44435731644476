import React, { useState } from "react";
import { Button, Col, Divider, Row, Tabs } from "antd";
import "./login.scss";
import AccountLogin from "./accountLogin";
import SmsLogin from "./smsLogin";
import LeftIcon from "@src/images/left.png";
import Logo from "@src/images/logo.png";
import classNames from "classnames";

const Login = () => {
  const [loginType, setLoginType] = useState<string>("account");

  const switchType = (type) => {
    setLoginType(type);
  };
  return (
    <div className="login-container">
      <div className="login-wrapper">
        <div className="login-main">
          <div className="login-content ">
            <h3
              className={classNames(
                "flex flex-row justify-center items-center text-[28px] py-[30px] gap-[16px]",
                "text-white"
              )}
            >
              <img src={Logo} alt="" />
              有求必应管理系统
            </h3>
            <div
              className={classNames(
                "flex flex-col justify-center items-center pb-[40px]",
                "rounded-[12px] bg-white w-full shadow-md transition-all"
              )}
            >
              <Row className="w-full mb-[30px] pl-[32px] pt-[20px]">
                <Col>
                  <div
                    className={classNames(
                      "text-[18px] text-[#d9d9d9] transition-all h-[40px] !rounded-none",
                      "flex flex-col justify-center items-center px-[8px] py-[10px] cursor-pointer",
                      loginType == "account"
                        ? " !text-black text-[20px] !rounded-tl-[6px] !rounded-br-[6px]"
                        : ""
                    )}
                    onClick={() => switchType("account")}
                  >
                    账号登录
                  </div>
                </Col>
                <Col>
                  <div
                    className={classNames(
                      "text-[18px] text-[#d9d9d9] transition-all h-[40px] !rounded-none",
                      "flex flex-col justify-center items-center px-[8px] py-[10px] cursor-pointer",
                      loginType == "phone"
                        ? "!text-black text-[20px] !rounded-bl-[6px] !rounded-tr-[6px]"
                        : ""
                    )}
                    onClick={() => switchType("phone")}
                  >
                    手机登录
                  </div>
                </Col>
              </Row>
              {loginType == "account" && <AccountLogin />}
              {loginType == "phone" && <SmsLogin />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
