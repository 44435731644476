const routes = [
  {
    path: "/order",
    menuName: "订单管理",
    icon: "BarsOutlined",
    code: [1],
    auth: (data) => {
      return data?.userRole != 3;
    },
    sort: 4,
    children: [
      {
        path: "list",
        menuName: "订单列表",
        index: true,
        element: () => import("./orderList"),
      },
      // {
      //   path: "works",
      //   menuName: "用户作品",
      //   menu: false,
      //   element: () => import("./userWorks"),
      // },
    ],
  },
];

export default routes;
