import React, { useEffect, useState, useCallback } from "react";
// import * as Icons from "@pack/icons";
import { Menu } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import Icons from "@package/icons";

// import "./index.less";

const { SubMenu } = Menu;

const SlideMenu = (props) => {
  const navigate = useNavigate();
  const _location = useLocation();

  const [menuList, setMenuList] = useState([]);

  useEffect(() => {
    if (Array.isArray(props?.list) && props?.list?.length > 0) {
      const currentList = props.list.map((item) => {
        if (item.icon) {
          item.iconComp = renderIcon(item);
        }
        return item;
      });

      setMenuList(currentList);
    }
  }, [props.list]);

  const changeMenu = (item) => {
    navigate(item.url || item.path || "/404");
  };

  //渲染Icon
  const renderIcon = (item) => {
    if (!!item.icon) {
      let ItemIcon = Icons[item.icon];

      if (!ItemIcon) ItemIcon = Icons["MenuOutlined"];
      return <ItemIcon></ItemIcon>;
    }
    return <></>;
  };

  const getDefaultSelectedKey = () => {
    return _location.pathname;
  };

  const getDefalutOpenKeys = (): Array<string> => {
    const _pathname = _location.pathname.split("/");

    let _keys: Array<string> = [];

    for (let i = 0; i < _pathname.length - 1; i++) {
      if (!!_pathname[i]) {
        const _p = _pathname.slice(0, i + 1);
        _keys.push(_p.join("/"));
      }
    }

    return _keys;
  };

  //判断是否包含子菜单
  const hasSubMenu = (menu) => {
    return (
      Array.isArray(menu.children) &&
      menu.children?.filter((item) => item.menu !== false)?.length > 0
    );
  };

  const _renderSubMenu = useCallback(
    (menu, index) => {
      return (
        <SubMenu key={menu.path} title={menu.menuName} icon={renderIcon(menu)}>
          {menu.children.map((item, subindex) => {
            if (item.menu === false)
              return (
                <React.Fragment key={`empty-${subindex}`}></React.Fragment>
              );

            return hasSubMenu(item) ? (
              _renderSubMenu(item, subindex)
            ) : (
              <Menu.Item
                key={item.path}
                onClick={() => changeMenu(item)}
                icon={renderIcon(item)}
              >
                {item.menuName}
              </Menu.Item>
            );
          })}
        </SubMenu>
      );
    },
    [menuList]
  );

  return (
    <div className="slide-menu-box">
      {!!menuList && menuList.length > 0 && (
        <Menu
          forceSubMenuRender={true}
          mode="inline"
          className="submenu-box"
          defaultOpenKeys={getDefalutOpenKeys()}
          selectedKeys={[getDefaultSelectedKey()]}
        >
          {menuList.map((menu, index) => {
            return hasSubMenu(menu) ? (
              _renderSubMenu(menu, index)
            ) : (
              <Menu.Item
                key={menu.path}
                onClick={changeMenu.bind(this, menu)}
                icon={
                  !!menu.icon ? (
                    renderIcon(menu)
                  ) : (
                    <div>{menu.menuName?.substr?.(0, 1)}</div>
                  )
                }
              >
                {menu.menuName}
              </Menu.Item>
            );
          })}
        </Menu>
      )}
    </div>
  );
};

export default SlideMenu;
