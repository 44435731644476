import React, { useImperativeHandle, useRef } from "react";
import { IFormItemProps, NormalForm } from "@package/form";

const UserProtocol = (props, ref) => {
  const normalRef = useRef();

  const _formItems: Array<IFormItemProps> = [
    {
      type: "editor",
      formItemProps: {
        name: "",
        label: "",
      },
    },
  ];

  useImperativeHandle(ref, () => normalRef.current, []);

  return (
    <>
      <NormalForm
        labelCol={{ span: 0 }}
        wrapperCol={{ span: 24 }}
        showActionBar={false}
        ref={normalRef}
        formItems={_formItems}
      ></NormalForm>
    </>
  );
};

export default React.forwardRef(UserProtocol);
