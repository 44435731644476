import React, { useRef } from "react";
import { RemoteTable } from "@package/table";
import { Button, Image, Space, Switch, message } from "antd";
import { RenderIcon } from "@package/icons";
import Edit from "./edit";
import { IModalFormRef } from "@package/form";
import { useLoadTableData } from "@src/javascripts/common/useTableCallback";
import { ResponseCode } from "@src/global/layout";
import { useDispatchAsync } from "@src/javascripts/common/useDispatch";

const CoBrand = () => {
  const [table] = RemoteTable.useTable();
  const _editRef = useRef<IModalFormRef>();

  const _columns = [
    // {
    //   title: "排序",
    //   dataIndex: "",
    // },
    {
      title: "ID",
      dataIndex: "brandCode",
    },
    {
      title: "品牌名称",
      filter: true,
      dataIndex: "brandName",
    },
    {
      title: "缩略图",
      dataIndex: "brandKey",
      filter: true,
      render: (data, record) => {
        return <Image src={data} />;
      },
    },
    {
      title: "状态",
      dataIndex: "statusName",
      render: (data, record) => {
        return (
          <Switch
            checkedChildren={"显示"}
            unCheckedChildren={"隐藏"}
            checked={record.status}
            onChange={async () => {
              const _res = await useDispatchAsync({
                type: "content/UpdateBrand",
                data: {
                  ...record,
                  status: record.status == 0 ? 1 : 0,
                  statusName: record.status == 0 ? "显示" : "隐藏",
                },
              });

              if (_res.code === ResponseCode.Success) {
                message.success("状态更改成功");
                table.reload();
                return;
              }
            }}
          ></Switch>
        );
      },
    },
    {
      title: "操作",
      render: (data, record) => {
        return (
          <Space>
            <Button
              type="link"
              icon={<RenderIcon type="EditOutlined" />}
              onClick={() => {
                _editCoBrand(record);
              }}
            >
              编辑
            </Button>
          </Space>
        );
      },
    },
  ];

  const _editCoBrand = (data?: any) => {
    _editRef.current.setVisible(true);
    if (data) {
      _editRef.current.setFieldsValue({
        ...data,
        brandKey: [{ url: data.brandKey, id: data.brandCode }],
      });
    }
  };

  return (
    <>
      <RemoteTable
        table={table}
        columns={_columns}
        rowKey={"brandCode"}
        loadData={useLoadTableData("content/BrandList")}
        actionBar={
          <Button
            type="primary"
            icon={<RenderIcon type="PlusOutlined" />}
            onClick={_editCoBrand}
          >
            新增品牌
          </Button>
        }
      ></RemoteTable>
      <Edit ref={_editRef} table={table} onClick={_editCoBrand}></Edit>
    </>
  );
};

export default CoBrand;
