import { IPageModel } from "@package/store";

export default {
  namespace: "goods",
  actionType: {
    Post: {
      GoodsList: "/model/product/list",
      AddGoods: "/model/product/saveOrUpdate",
      UpdateGoods: "/model/product/saveOrUpdate",
    },
  },
} as IPageModel;
