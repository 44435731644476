import React, { useEffect, useState } from "react";
import { Form, Button, Input, message } from "antd";
import { RenderIcon } from "@package/icons";
import classNames from "classnames";
import {
  useDispatchAsync,
  useLocalDispatch,
} from "@src/javascripts/common/useDispatch";
import { ResponseCode } from "@src/global/layout";
import token from "@src/javascripts/common/storage/token";
import { useNavigate } from "react-router-dom";
import { useCountdown } from "usehooks-ts";

const SmsLogin = (props) => {
  const [form] = Form.useForm();
  const [errorMsg, setErrorMessage] = useState(null);
  const navigate = useNavigate();

  const [intervalValue, setIntervalValue] = useState<number>(1000);
  const [
    count,
    { startCountdown, stopCountdown, resetCountdown },
  ] = useCountdown({
    countStart: 60,
    intervalMs: intervalValue,
  });

  const sendAuthCode = async () => {
    if (!form.getFieldValue("tel")) {
      setErrorMessage("手机号码不能为空");
      return;
    }
    
    let _res = await useDispatchAsync({
      type: "login/SendMessage",
      data: {
        tel: form.getFieldValue("tel"),
      },
    });

    if (_res.code === ResponseCode.Success) {
      message.success("验证码已发送，请注意查收");
      startCountdown();
      return;
    }
  };

  const login = async () => {
    await form.validateFields();

    const res = await window.$store.dispatchAsync({
      type: "login/Login",
      data: form.getFieldsValue(),
    });

    if (res.code === ResponseCode.Success) {
      token.save(res.data.access_token);
      navigate("/");
      setTimeout(() => {
        useLocalDispatch({
          type: "global/UserProfile",
        });
      });
      return;
    }

    // if (res.code != 0) {
    message.error(res.message);
    return;
    // }
  };

  useEffect(() => {
    if (count == 0) resetCountdown();
  }, [count]);

  console.log(count, "--count");

  return (
    <div className="account-login">
      <Form
        form={form}
        autoComplete={"off"}
        className="flex gap-[12px] flex-col"
      >
        <Form.Item
          name={"tel"}
          rules={[{ required: true, message: "账号不能为空" }]}
        >
          <Input
            prefix={<RenderIcon type="MobileOutlined" />}
            allowClear={true}
            placeholder="请输入手机号码"
          />
        </Form.Item>
        <Form.Item>
          <Input.Group
            style={{ width: "100%" }}
            className={classNames("flex flex-row justify-between")}
          >
            <Form.Item
              rules={[{ required: true, message: "验证码不能为空" }]}
              style={{
                width: `calc(100% - 120px)`,
                borderRadius: "4px 0 0 4px",
              }}
              name={"code"}
              noStyle
            >
              <Input
                className="code"
                prefix={<RenderIcon type="SafetyOutlined" />}
                allowClear={true}
                placeholder="验证码"
              />
            </Form.Item>
            <Button
              type="default"
              className={classNames(
                "!h-[50px] text-[14px] w-[100px]",
                count > 0 && count < 60 && "text-black"
              )}
              onClick={sendAuthCode}
              disabled={count > 0 && count < 60}
            >
              {count > 0 && count < 60 ? `重新发送${count}s` : "发送验证码"}
            </Button>
          </Input.Group>
          <div className={classNames("text-red-500 text-[14px] pt-[8px]")}>
            {errorMsg}
          </div>
        </Form.Item>
      </Form>
      <div className="field-item" style={{ marginTop: "20px" }}>
        <Button type="primary" onClick={login}>
          登录
        </Button>
      </div>
    </div>
  );
};

export default SmsLogin;
