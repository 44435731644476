import { Card, Col, Row, Statistic } from "antd";
import React from "react";

const SummaryItems = [
  {
    label: "销售额（元）",
    value: "saleAmount",
  },
  {
    label: "订单数（笔）",
    value: "orderNum",
    type: "int",
  },
  {
    label: "访客量（人次）",
    value: "visitNum",
    type: "int",
  },
  {
    label: "新增用户（人次）",
    value: "addUserNum",
    type: "int",
  },
];

const ListCards = (props) => {
  const { data = {} } = props;

  return (
    <>
      <Row gutter={[16, 16]}>
        {SummaryItems.map((item, index) => {
          const _data = data?.[item.value] || {};
          return (
            <Col span={12} sm={12} xs={24} xl={6} key={`card-${index}`}>
              <Card
                actions={[
                  <>昨天：{_data.yesterdayTotal}</>,
                  <>本月：{_data.currentMonth}</>,
                ]}
              >
                <Statistic
                  title={item.label}
                  value={_data?.total || 0}
                  precision={item.type === "int" ? 0 : 2}
                ></Statistic>
              </Card>
            </Col>
          );
        })}
      </Row>
    </>
  );
};

export default ListCards;
