import { IPageModel } from "@package/store";

export default {
  namespace: "statictis",
  actionType: {
    Post: {
      SaleStatictis: "/model/order/statistics",
      UserStatictis: "/model/user/statistics",
    },
  },
} as IPageModel;
