const routes = [
  {
    path: "/login",
    menu: false,
    menuName: "Login",
    icon: "DashboardOutlined",
    element: () => import("./app"),
    layout: false,
    shouldAuth: false
  },
];

export default routes;
