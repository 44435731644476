export const Post = {};

export const actionType = {
  Post: {
    Login: "/model/auth/login",
    SendMessage: "/model/auth/sendSms",
  },
  Get: {
    Logout: "/model/auth/logout",
  },
};

export default {
  namespace: "login",
  actionType,
  reducers: {
    loginInfo: (state = {}, action) => {
      switch (action.type) {
        case "Login@@auto":
          return { ...state, token: action.data };
        default:
          return state;
      }
    },
  },
};
