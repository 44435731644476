import { ResponseCode } from "@src/global/layout";
import { message } from "antd";

export const useDispatchAsync = async (option) => {
  const _result = await window.$store.dispatchAsync(option);

  if (_result.code === ResponseCode.Success) {
    return _result;
  }

  option?.error?.(_result) ?? message.error(_result.msg);

  return _result;
};

export const useLocalDispatch = (option) => {
  // console.log(option, "option");
  return window.$store.dispatchAsync(option);
};
