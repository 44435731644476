import React, { useRef, useImperativeHandle } from "react";
import { IFormGroupItemProps, IFormItemProps, ModalForm } from "@package/form";
import classNames from "classnames";
import { useDispatchAsync } from "@src/javascripts/common/useDispatch";
import { ResponseCode } from "@src/global/layout";
import { message } from "antd";

const EditGoods = (props, ref) => {
  const { table } = props || {};
  const modalRef = useRef();

  const _formItems: Array<IFormItemProps> = [
    {
      type: "label",
      formItemProps: {
        name: "productId",
        hidden: true,
      },
    },
    {
      type: "label",
      formItemProps: {
        name: "productCode",
        hidden: true,
      },
    },
    {
      formItemProps: {
        name: "productName",
        label: "商品名称",
        rules: [{ required: true }],
      },
    },
    {
      type: "textarea",
      formItemProps: {
        name: "productDesc",
        label: "商品描述",
      },
    },
    {
      type: "section",
      title: "商品内容",
      className: classNames("p-[12px] border-[1px] rounded-[8px] mb-[12px]"),
      children: [
        {
          type: "group",
          spaceProps: {
            direction: "vertical",
            className: classNames("!gap-0 w-full"),
          },
          children: [
            {
              formItemProps: {
                name: ["productDetails", 0, "detailId"],
                initialValue: null,
                hidden: true,
              },
              formControlProps: {
                min: 0,
              },
            },
            {
              type: "inputnumber",
              formItemProps: {
                name: ["productDetails", 0, "quantity"],
                label: "平面图案生成次数",
                labelCol: { span: 8 },
                wrapperCol: { span: 16 },
                rules: [{ required: true }],
                initialValue: 0,
                noStyle: false,
              },
              formControlProps: {
                min: 0,
                placeholder: "请输入次数",
              },
            },
          ],
        },
        {
          type: "group",
          spaceProps: {
            direction: "vertical",
            className: classNames("!gap-0 w-full"),
          },
          children: [
            {
              formItemProps: {
                name: ["productDetails", 1, "detailId"],
                initialValue: null,
                hidden: true,
              },
              formControlProps: {
                min: 0,
              },
            },
            {
              type: "inputnumber",
              formItemProps: {
                name: ["productDetails", 1, "quantity"],
                label: "3D模型文件生成次数",
                labelCol: { span: 8 },
                wrapperCol: { span: 16 },
                rules: [{ required: true }],
                initialValue: 0,
                noStyle: false,
              },
              formControlProps: {
                min: 0,
                placeholder: "请输入次数",
              },
            },
          ],
        },
      ],
    },
    {
      children: [
        {
          type: "inputnumber",
          formItemProps: {
            name: "originalPrice",
            label: "划线价",
            rules: [{ required: true }],
            labelCol: { span: 12 },
            wrapperCol: { span: 12 },
            initialValue: 0,
          },
        },
        {
          type: "inputnumber",
          formItemProps: {
            className: classNames("sm:pl-[8px]"),
            name: "currentPrice",
            label: "售价",
            rules: [{ required: true }],
            labelCol: { span: 6 },
            wrapperCol: { span: 18 },
            initialValue: 0,
            labelAlign: "right",
          },
        },
      ],
    },
    {
      formItemProps: {
        name: "status",
        initialValue: 0,
        hidden: true,
      },
    },
    {
      formItemProps: {
        name: "statusName",
        initialValue: "隐藏",
        hidden: true,
      },
    },
    {
      type: "inputnumber",
      formItemProps: {
        name: "sort",
        label: "排序",
      },
    },
  ];

  useImperativeHandle(ref, () => modalRef.current, []);

  const onSave = async (data) => {
    const { productDetails, productCode, ...restData } = data;

    const _res = await useDispatchAsync({
      type: !!productCode ? "goods/UpdateGoods" : "goods/AddGoods",
      data: {
        ...restData,
        productCode,
        productDetails: productDetails.map((item, index) => {
          return {
            ...item,
            detailName: index == 0 ? "平面图案" : "3D模型文件",
          };
        }),
      },
    });

    if (_res.code === ResponseCode.Success) {
      message.success("商品添加成功");
      table?.reload?.();
      return false;
    }

    return true;
  };

  return (
    <>
      <ModalForm
        ref={modalRef}
        formItems={_formItems}
        title="新增/修改商品"
        width={680}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        onOk={onSave}
      ></ModalForm>
    </>
  );
};

export default React.forwardRef(EditGoods);
