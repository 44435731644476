import React from "react";
import { Result, Button } from "antd";
import { useNavigate } from "react-router-dom";

const Page500 = () => {
  const navigate = useNavigate();

  return (
    <Result
      status="500"
      title="500"
      subTitle="抱歉，出了一些问题。 "
      extra={
        <Button
          type="primary"
          onClick={() => {
            navigate("/");
          }}
        >
          返回
        </Button>
      }
    />
  );
};
export default Page500;
