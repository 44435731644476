import React, { useRef } from "react";
import { RemoteTable } from "@package/table";
import { Button, Space, Switch, Image, message } from "antd";
import { IModalFormRef } from "@package/form";
import EditWorks from "./edit";
import { RenderIcon } from "@package/icons";
import { useLoadTableData } from "@src/javascripts/common/useTableCallback";
import { useDispatchAsync } from "@src/javascripts/common/useDispatch";
import { ResponseCode } from "@src/global/layout";

const WorksGallery = () => {
  const [table] = RemoteTable.useTable();
  const _editRef = useRef<IModalFormRef>();

  const _columns = [
    {
      title: "商品码",
      dataIndex: "categoryCode",
    },
    {
      title: "产品名称",
      dataIndex: "categoryName",
      filter: true,
    },
    {
      title: "产品材质",
      dataIndex: "categoryMaterial",
      filter: true,
    },
    {
      title: "产品工艺",
      dataIndex: "categoryProcess",
      // filter: true,
    },
    {
      title: "缩略图",
      dataIndex: "categoryKey",
      render: (data, record) => {
        return <Image src={data} alt={record.categoryName} />;
      },
    },
    {
      title: "状态",
      // filter: true,
      dataIndex: "statusName",
      render: (data, record) => {
        return (
          <Switch
            checked={!!record.status}
            checkedChildren="显示"
            unCheckedChildren="隐藏"
            onChange={async () => {
              const _res = await useDispatchAsync({
                type: "content/UpdateProduct",
                data: {
                  ...record,
                  status: record.status == 1 ? 0 : 1,
                  statusName: record.status == 1 ? "隐藏" : "显示",
                },
              });

              if (_res.code === ResponseCode.Success) {
                message.success("保存成功");
                table.reload();
                return;
              }
              return true;
            }}
          ></Switch>
        );
      },
    },
    {
      title: "操作",
      render: (data, record) => {
        return (
          <Space>
            <Button
              type="link"
              onClick={() => {
                _editWorks(record);
              }}
            >
              编辑
            </Button>
          </Space>
        );
      },
    },
  ];

  const _editWorks = (data) => {
    _editRef.current.setVisible(true);

    if (data) {
      const { categoryKey, ...restData } = data;
      _editRef.current.setFieldsValue({
        ...restData,
        categoryKey: [{ url: categoryKey, id: restData.categoryCode }],
      });
    }
  };

  return (
    <>
      <RemoteTable
        table={table}
        columns={_columns}
        loadData={useLoadTableData("content/ProductList")}
        rowKey={"categoryCode"}
        scroll={{ y: true }}
        actionBar={
          <Button
            type="primary"
            icon={<RenderIcon type="PlusOutlined" />}
            onClick={_editWorks}
          >
            新增产品
          </Button>
        }
      ></RemoteTable>
      <EditWorks ref={_editRef} table={table}></EditWorks>
    </>
  );
};

export default WorksGallery;
