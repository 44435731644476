import token from "@src/javascripts/common/storage/token";
import { message } from "antd";
import { redirect, useNavigate } from "react-router-dom";

export const ResponseCode = {
  Success: "OK",
  Unauthorized: 401,
  Forbidden: 403,
  NotFound: 404,
  NoPermission: 10003,
  NOLogin: 10002,
  DataRepeat: 10010,
};

const _domainUrl = process.env.NODE_ENV === "production" ? "" : "";

export const frontUrl =
  process.env.NODE_ENV === "production"
    ? "https://zjcatholic.org"
    : "http://localhost:1226";

export default {
  systemTitle: "有求必应后台系统",

  serviceSetting: {
    initService: (Service) => {
      Service.interceptors.request.use((config) => {
        config.baseURL = _domainUrl;

        // config.headers["content-type"] = "application/x-www-form-urlencoded";

        if (!config) return config;

        //@ts-ignore
        config.headers["authorization"] = localStorage.getItem("token");
        // console.log(config, '---config')
        return config;
      });

      const ErrorHandle = (response) => {
        if (response.status != 200) {
          if (response.status === 401) {
            localStorage.removeItem("token");
            token.remove();
            // window.location.href = "/login";
          } else {
            //   message.error(response.statusText || response.message || "接口请求失败");
          }

          return {
            code: response.status,
            message: response.message ?? response.statusText,
            data: null,
          };
        }
      };

      Service.interceptors.response.use(
        function (response) {
          // const navigate = useNavigate();
          console.log(response, "response",response?.headers?.authorization);
          try {
            // console.log(response, "response");
            if (response.status != 200) {
              return ErrorHandle(response);
            }

            //保存token
            if (!!response?.headers?.authorization) {
              token.save(response?.headers?.authorization);
            }

            //@ts-ignore
            if (response.config?.responseType === "arraybuffer") {
              return response;
            }

            switch (response?.data?.code) {
              case ResponseCode.NOLogin:
                message.error("登录已过期");
                token.remove();
                window.location.href = "/login";
                return;
              case ResponseCode.NoPermission:
              case ResponseCode.Unauthorized:
              case ResponseCode.Forbidden:
                // return redirect("/403");
                return response.data;
              // case ResponseCode.NotFound:
              //   window.location.href = "/404";
              default:
                return response.data;
            }
          } catch (ex) {
            return response;
          }
        },
        function (error) {
          console.log(error, "error");
          if (error?.response?.status === ResponseCode.Unauthorized) {
            message.error("登录授权已过期");
            token.remove();
            window.location.href = "/login";
            return;
          }
          if (error?.response) {
            const response = error.response;
            if (response.data instanceof ArrayBuffer) {
              let utf8decoder = new TextDecoder();

              const _data = utf8decoder.decode(response.data);

              return ErrorHandle(JSON.parse(_data));
            } else return ErrorHandle(response);
          }

          return {
            code: 500,
            msg: error.message ?? "接口有误",
          };
        }
      );
    },
    BaseUrl: "",
    UPLOAD_URL: _domainUrl + "/backapi/upload/file",
    EDITOR_UPLOAD_URL: _domainUrl + "/backapi/upload/content_file",
    apiDomain: "",
    logout: () => {},
    responseCode: ResponseCode,
  },
  layoutSetting: {
    sidebarOption: {
      defaultCollapse: false,
      collapsedWidth: 80,
      defaultWidth: 260,
    },
  },
};
