const routes = [
    {
      path: "/",
      menuName: "首页",
      icon: "DashboardOutlined",
      element: () => import("./app"),
      sort: 1,
    },
  ];
  
  export default routes;
  