import React, { useRef, useImperativeHandle } from "react";
import { IFormItemProps, IModalFormRef, ModalForm } from "@package/form";

const Refund = (props, ref) => {
  const modalRef = useRef<IModalFormRef>();

  useImperativeHandle(ref, () => modalRef.current, []);

  const _formItems: Array<IFormItemProps> = [
    {
      type: "label",
      formItemProps: {
        name: "orderCode",
        hidden: true,
      },
    },
    {
      formItemProps: {
        name: "refundPayment",
        label: "退款金额",
      },
    },
    {
      type: "label",
      formItemProps: {
        name: "orderPayment",
        label: "最高可退",
      },
    },
  ];

  return (
    <ModalForm formItems={_formItems} ref={modalRef} title="退款"></ModalForm>
  );
};

export default React.forwardRef(Refund);
