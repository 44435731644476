import React from "react";
import { RemoteTable } from "@package/table";
import { Button, Space } from "antd";
import { RenderIcon } from "@package/icons";
import { useLoadTableData } from "@src/javascripts/common/useTableCallback";

const SalesSummary = () => {
  const _columns = [
    {
      title: "日期",
      dataIndex: "statisticsDate",
      filter: true,
      type: "daterange",
    },
    {
      title: "商品码",
      dataIndex: "productCode",
    },
    {
      title: "商品名称",
      dataIndex: "productName",
    },
    {
      title: "商品内容",
      dataIndex: "productDetails",
      render: (data) => {
        return (
          <Space direction="vertical">
            {(data || []).map((item) => {
              return `${item.detailName}【${item.quantity}】`;
            })}
          </Space>
        );
      },
    },
    {
      title: "订单数",
      dataIndex: "orderNum",
    },
    {
      title: "销售额",
      dataIndex: "amount",
    },
  ];
  return (
    <>
      <RemoteTable
        columns={_columns}
        loadData={useLoadTableData("statictis/SaleStatictis", (searchParam) => {
          if (searchParam.statisticsDate) {
            return {
              startTime: searchParam.statisticsDate[0],
              endTime: searchParam.statisticsDate[1],
            };
          }

          return {};
        })}
        tableTitle={
          <Space direction="horizontal" size={"large"}>
            <span className="font-semibold">合计</span>
            <span>
              <span className="font-semibold">订单数：</span> 123
            </span>
            <span>
              <span className="font-semibold">销售额：</span> 123
            </span>
          </Space>
        }
        actionBar={() => {
          return (
            <>
              <Space className="!gap-[12px]">
                <Button
                  type="primary"
                  icon={<RenderIcon type="DownloadOutlined"></RenderIcon>}
                >
                  下载
                </Button>
              </Space>
            </>
          );
        }}
      ></RemoteTable>
    </>
  );
};

export default SalesSummary;
