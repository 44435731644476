import { IPageModel } from "@package/store";

const WorksGallery = {
  Post: {
    ProductList: "/model/category/list",
    AddProduct: "/model/category/saveOrUpdate",
    UpdateProduct: "/model/category/saveOrUpdate",
  },
};

const Brands = {
  Post: {
    BrandList: "/model/brand/list",
    AddBrand: "/model/brand/saveOrUpdate",
    UpdateBrand: "/model/brand/saveOrUpdate",
  },
};

export default {
  namespace: "content",
  actionType: {
    Post: { ...WorksGallery.Post, ...Brands.Post },
  },
} as IPageModel;
